import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="invisible-braces hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>This Page Not Exists</h1>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default NotFoundPage
